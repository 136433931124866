@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #FFE5EC; /* Fallback color */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}